.swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 400px;
  height: 400px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.t-pic {
  width: 60px !important;
  border-radius: 50% !important;
}

.swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  background: #25d366 !important;
}

